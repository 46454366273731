import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";

export default function Insured({
  incrementStep,
  decrementStep,
  address,
  startDate,
  endDate,
  customerForm,
  setCustomerForm,
}) {
  const [insuredType, setInsuredType] = useState("individual");
  const [insuredName, setInsuredName] = useState("");
  const [contacMethod, setContactMethod] = useState("email");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  function handleInsuredName(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      insured_name: e.target.value,
    }));
  }

  function handleEmail(e) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    setIsValidEmail(emailRegex.test(e.target.value));
    setCustomerForm((prevState) => ({
      ...prevState,
      correspondence_email: e.target.value,
    }));
  }

  function handlePhone(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      correspondence_phone: e.target.value,
    }));
  }

  function handleContactName(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      contact_name: e.target.value,
    }));
  }

  function handleAgencyName(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      agency_name: e.target.value,
    }));
  }

  function handleProxyName(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      proxy_name: e.target.value,
    }));
  }

  function handlePostalAddress(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      postal_address: e.target.value,
    }));
  }

  function handleInsuredTypeChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      insured_type: e,
    }));
  }

  function handleContactMethodChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      contact_method: e,
    }));
  }

  const isButtonDisabled =
    !customerForm.insured_name ||
    !customerForm.correspondence_email ||
    !customerForm.correspondence_phone ||
    !customerForm.contact_name ||
    !customerForm.postal_address;

  return (
    <div className="max-w-[85vw] mx-auto">
      <div className="relative bg-white p-6 md:p-10 rounded-xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-[60%]">
            <div className="flex flex-col justify-center mb-5">
              <div className="mb-6">
                <h2 className="text-[#202020] text-[20px] font-semibold">
                  Insured
                </h2>
              </div>
              <div className="mb-4">
                <p className="text-[#576263] text-[16px] font-light">
                  Insured Type
                </p>
                <div className="flex mt-2">
                  <button
                    onClick={() => handleInsuredTypeChange("individual")}
                    className={`py-1 px-4 rounded-[3rem] rounded-r-none ${
                      customerForm.insured_type === "individual"
                        ? "bg-[#2B87C8] text-white"
                        : "bg-white border border-[#D0D5DD] text-[#202020]"
                    }`}
                  >
                    Individual
                  </button>
                  <button
                    onClick={() => handleInsuredTypeChange("non_individual")}
                    className={`py-1 px-4 rounded-[3rem] rounded-l-none ${
                      customerForm.insured_type === "non_individual"
                        ? "bg-[#2B87C8] text-white"
                        : "bg-white border border-[#D0D5DD] text-[#202020]"
                    }`}
                  >
                    Non-Individual
                  </button>
                </div>
              </div>
              <div className="flex flex-col mb-2">
                <label
                  htmlFor="insuredName"
                  className="text-[#576263] text-[16px] font-light mb-2"
                >
                  Insured Name
                </label>
                <input
                  type="text"
                  required={true}
                  id="insuredName"
                  name="insured_name"
                  placeholder="e.g. John Doe"
                  value={customerForm.insured_name}
                  onChange={handleInsuredName}
                  className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
                />
              </div>
              <hr className="my-6" />
              <div className="mb-4">
                <p className="text-[#576263] text-[16px] font-light">
                  Contact Method
                </p>
                <div className="flex mt-2">
                  <button
                    onClick={() => handleContactMethodChange("email")}
                    className={`py-1 px-4 rounded-[3rem] rounded-r-none ${
                      customerForm.contact_method === "email"
                        ? "bg-[#2B87C8] text-white"
                        : "bg-white border border-[#D0D5DD] text-[#202020]"
                    }`}
                  >
                    Email
                  </button>
                  <button
                    onClick={() => handleContactMethodChange("phone")}
                    className={`py-1 px-4 rounded-[3rem] rounded-l-none ${
                      customerForm.contact_method === "phone"
                        ? "bg-[#2B87C8] text-white"
                        : "bg-white border border-[#D0D5DD] text-[#202020]"
                    }`}
                  >
                    Phone
                  </button>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-2 md:gap-5">
                <div className="flex flex-col mb-2">
                  <label
                    htmlFor="email"
                    className="text-[#576263] text-[16px] font-light mb-2"
                  >
                    Correspondence Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="correspondence_email"
                    placeholder="e.g. johndoe@gmail.com"
                    value={customerForm.correspondence_email}
                    onChange={handleEmail}
                    className={
                      isValidEmail
                        ? "form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border  border-gray-300 w-[300px]"
                        : "form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-red-500 w-[300px]"
                    }
                  />
                  {!isValidEmail && (
                    <p className="text-red-500 mt-2">
                      Please enter a valid email.
                    </p>
                  )}
                </div>

                <div className="flex flex-col mb-2">
                  <label
                    htmlFor="email"
                    className="text-[#576263] text-[16px] font-light mb-2"
                  >
                    Correspondence Phone
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="correspondence_phone"
                    placeholder="e.g. +61 123 456 789"
                    value={customerForm.correspondence_phone}
                    onChange={handlePhone}
                    className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
                  />
                </div>
              </div>
              <hr className="my-6" />
              <div className="flex flex-col mb-2">
                <label
                  htmlFor="email"
                  className="text-[#576263] text-[16px] font-light mb-2"
                >
                  Contact Name
                </label>
                <input
                  type="text"
                  id="contactName"
                  name="contact_name"
                  placeholder="e.g. John Doe"
                  value={customerForm.contact_name}
                  onChange={handleContactName}
                  className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
                />
              </div>
              <div className="flex flex-col mb-2">
                <label
                  htmlFor="insuranceStartDate"
                  className="text-[#576263] block font-light mb-2"
                >
                  Postal Address
                </label>
                <textarea
                  type="text"
                  id="insuredAddress"
                  name="postal_address"
                  value={customerForm.postal_address}
                  onChange={handlePostalAddress}
                  placeholder="Enter postal address"
                  className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-full"
                />
              </div>
              <hr className="my-6" />
              <div className="flex flex-col mb-2">
                <label
                  htmlFor="email"
                  className="text-[#576263] text-[16px] font-light mb-2"
                >
                  Agency Name
                </label>
                <input
                  type="text"
                  id="agencyName"
                  name="agency_name"
                  placeholder="e.g. Ray White"
                  value={customerForm.agency_name}
                  onChange={handleAgencyName}
                  className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
                />
              </div>
              <hr className="my-6" />
              <div className="flex flex-col mb-2">
                <label
                  htmlFor="email"
                  className="text-[#576263] text-[16px] font-light mb-3"
                >
                  Is this form being completed on someone elses behalf?
                </label>
                <input
                  type="text"
                  id="proxyName"
                  name="proxy_name"
                  placeholder="e.g. Jane Doe"
                  value={customerForm.proxy_name}
                  onChange={handleProxyName}
                  className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
                />
              </div>
            </div>
            <div className="mb-6">
              <h2 className="text-[#202020] text-[16px] font-semibold mb-2">
                Terms and Conditions
              </h2>
              <div className="flex flex-col items-start my-4">
                <div className="flex items-center">
                  <input
                    id="declaration"
                    type="checkbox"
                    checked={customerForm.terms_and_conditions}
                    onChange={() => {
                      setCustomerForm((prevState) => ({
                        ...prevState,
                        terms_and_conditions:
                          !customerForm.terms_and_conditions,
                      }));
                      setShowTermsError(false);
                    }}
                    className={`w-5 h-5 bg-gray-100 rounded cursor-pointer ${
                      showTermsError
                        ? "border-red-500"
                        : "text-[#2B87C8] border-gray-400"
                    } focus:ring-[#2B87C8]`}
                  />
                  <label
                    htmlFor="declaration"
                    className="ms-2 text-sm font-medium text-[#202020] text-[14px] cursor-pointer"
                  >
                    I / We agree to the{" "}
                    <a
                      href="#termsAndConditions"
                      className="text-[#2B87C8] underline"
                    >
                      Terms and Conditions
                    </a>{" "}
                    of the policy.
                  </label>
                </div>
                {showTermsError && (
                  <p className="text-red-500 text-[13px] mt-2 ml-7">
                    Please check the declaration before proceeding.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-full md:w-[40%]">
            <div className="flex justify-start mb-4 md:mb-0 md:justify-end">
              <div className="flex flex-col gap-4">
                <div className="bg-[#2B87C8] w-full md:w-[320px] text-white shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div>
                    <h4 className="text-[16px]">Landlord Protection</h4>
                    <p className="text-[14px] font-light">Value Plus</p>
                  </div>
                  <hr className="my-4" />
                  <div className="flex items-center gap-4 cursor-pointer">
                    <h4 className="text-[16px]">
                      Product Disclosure Statement
                    </h4>
                    <MdArrowOutward className="w-5 h-5" />
                  </div>
                  <hr className="my-4" />
                  <div>
                    <h4 className="text-[16px]">Estimated Premium</h4>
                    <h5 className="text-[32px] font-semibold pb-2">
                      {"$361.98"}
                    </h5>
                    <p className="text-[12px] font-light">
                      {"(Including GST and Government Charges)"}
                    </p>
                  </div>
                </div>
                <div className="bg-white w-full md:w-[320px] text-[#576263] border border-[#CDD4D4] shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Property Address
                    </h4>
                    <p className="text-[14px] font-light">
                      {address || "Not specified."}
                    </p>
                  </div>
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Period of Insurance
                    </h4>
                    <p className="text-[14px] font-light">
                      {startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : "Not specified."}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-[16px] font-semibold">Quote Ref</h4>
                    <p className="text-[14px] font-light">Q-588820</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-end mt-4">
          <button
            onClick={decrementStep}
            className="flex bg-[#657375] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            <FaArrowLeft className="text-[14px]" />
            Previous
          </button>
          <button
            disabled={isButtonDisabled}
            onClick={() => {
              if (customerForm.terms_and_conditions && isValidEmail) {
                incrementStep();
              } else {
                setShowTermsError(true);
              }
            }}
            className={
              isButtonDisabled
                ? "flex bg-[#657375] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
                : "flex bg-[#2B87C8] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
            }
          >
            Next
            <FaArrowRight className="text-[14px]" />
          </button>
        </div>
      </div>
    </div>
  );
}
