import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";
import { ClipLoader } from "react-spinners";

export default function Premium({
  incrementStep,
  decrementStep,
  address,
  startDate,
  endDate,
  customerForm,
  setCustomerForm,
  sendData,
  isSaving,
  setIsSaving,
}) {
  const [paymentMethod, setPaymentMethod] = useState("payNow");

  function handlePayNowPayLaterChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      pay_now_pay_later: e,
    }));
  }

  return (
    <div className="max-w-[85vw] mx-auto">
      <div className="relative bg-white p-6 md:p-10 rounded-xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-[60%]">
            <div className="flex flex-col justify-center mb-5">
              <div className="mb-6">
                <h2 className="text-[#202020] text-[20px] font-semibold">
                  Premium
                </h2>
              </div>
              <h3 className="text-[#202020] text-[16px] font-semibold">
                Cover Options
              </h3>
              <div className="my-7">
                <p className="text-[#576263] text-[16px] font-light">
                  Level of Cover
                </p>
                <p className="text-[#2fa8ff] text-[20px] font-semibold">
                  Value Plus
                </p>
              </div>
              <div className="my-4">
                <p className="text-[#576263] text-[16px] font-light">
                  Select a premium payment method
                </p>
                <div className="flex gap-2 mt-2">
                  <button
                    onClick={() => handlePayNowPayLaterChange("pay_now")}
                    className={`py-1 px-4 rounded-[3rem] ${
                      customerForm.pay_now_pay_later === "pay_now"
                        ? "bg-[#2B87C8] text-white"
                        : "bg-white border border-[#D0D5DD] text-[#202020]"
                    }`}
                  >
                    Pay Now
                  </button>
                  <button
                    onClick={() => handlePayNowPayLaterChange("pay_later")}
                    className={`py-1 px-4 rounded-[3rem] ${
                      customerForm.pay_now_pay_later === "pay_later"
                        ? "bg-[#2B87C8] text-white"
                        : "bg-white border border-[#D0D5DD] text-[#202020]"
                    }`}
                  >
                    Pay Later
                  </button>
                </div>
              </div>
              <div className="my-4">
                <p className="text-[#576263] text-[16px] font-light">
                  Premium payment frequency
                </p>
                <p className="text-[#576263] text-[16px] font-semibold">
                  Annual
                </p>
              </div>
              <hr className="my-4" />
              <div className="my-4">
                <h4 className="text-[16px] text-[#576263]">Premium</h4>
                <h5 className="text-[32px] text-[#2fa8ff] font-semibold pb-2">
                  {"$361.98"}
                </h5>
                <p className="text-[12px] text-[#576263] font-light">
                  {"(Including GST and Government Charges)"}
                </p>
                <div className="flex items-center text-[#2B87C8] gap-2 cursor-pointer mt-4">
                  <h4 className="text-[14px]">Show breakdown</h4>
                  <MdArrowOutward className="w-4 h-4" />
                </div>
              </div>
              <hr className="my-4" />
              <div className="my-4">
                <h4 className="text-[16px] text-[#576263] font-semibold mb-2">
                  Need Assistance
                </h4>
                <p className="text-[#576263] text-[14px]">
                  If you have questions regarding this quote and would like to
                  discuss it with our staff, please give us a call or email us.
                  Have your quote number ready when you call or include this in
                  your email.
                </p>
                <div className="mt-4">
                  <h4 className="text-[#576263] text-[16px] font-semibold">
                    Quote Ref
                  </h4>
                  <p className="text-[#576263] text-[14px] font-light">
                    Q-588820
                  </p>
                </div>
              </div>
              <hr className="my-4" />
              <div className="my-4">
                <h4 className="text-[16px] text-[#576263] font-semibold mb-2">
                  Cover Summary
                </h4>
                <div className="relative overflow-x-auto">
                  <table className="w-full text-[12px] md:text-[14px] text-left rtl:text-right text-[#576263]">
                    <thead className="text-gray-700 bg-[#EAECF0]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Cover
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Replacement Value
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Excess
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Building
                        </th>
                        <td className="px-6 py-3">Not Covered</td>
                        <td className="px-6 py-3">{""}</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Contents
                        </th>
                        <td className="px-6 py-3">$70,000</td>
                        <td className="px-6 py-3">$250</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Loss of Rent
                        </th>
                        <td className="px-6 py-3">Up to 52 weeks</td>
                        <td className="px-6 py-3">Nil</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Deliberate Damage and Theft by Tenant
                        </th>
                        <td className="px-6 py-3">Up to $70,000</td>
                        <td className="px-6 py-3">1 week rent</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Tenant Default
                        </th>
                        <td className="px-6 py-3">Up to 20 weeks</td>
                        <td className="px-6 py-3">1 week rent</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Legal Liability
                        </th>
                        <td className="px-6 py-3">Up to $20,000,000</td>
                        <td className="px-6 py-3">Nil</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="my-4">
                <h4 className="text-[16px] text-[#576263] font-semibold mb-2">
                  Fixed Excesses
                </h4>
                <div className="relative overflow-x-auto">
                  <table className="w-full text-[12px] md:text-[14px] text-left rtl:text-right text-[#576263]">
                    <thead className="text-gray-700 bg-[#EAECF0]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Fixed Excess
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Replacement Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Flood
                        </th>
                        <td className="px-6 py-3">Additional $500</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Earthquake, cyclone, tsunami, or volcanic eruption
                        </th>
                        <td className="px-6 py-3">$5,000</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Accidental damage
                        </th>
                        <td className="px-6 py-3">$250</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Theft by tenant
                        </th>
                        <td className="px-6 py-3">$250</td>
                      </tr>
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium whitespace-nowrap"
                        >
                          Electric motor burnout
                        </th>
                        <td className="px-6 py-3">$300</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="my-4">
                <h4 className="text-[16px] text-[#576263] font-semibold mb-2">
                  Additional Benefits
                </h4>
                <div className="flex items-center text-[#2B87C8] gap-2 cursor-pointer mt-2">
                  <h4 className="text-[14px]">View Details</h4>
                  <MdArrowOutward className="w-4 h-4" />
                </div>
              </div>
              <div className="my-4">
                <h4 className="text-[16px] text-[#576263] font-semibold mb-2">
                  Endorsements
                </h4>
                <div className="relative overflow-x-auto">
                  <table className="w-full text-[12px] md:text-[14px] text-left rtl:text-right text-[#576263]">
                    <thead className="text-gray-700 bg-[#EAECF0]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Cover
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Replacement Value
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Excess
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b">
                        <th className="px-6 py-3 font-medium whitespace-nowrap">
                          No data available
                        </th>
                        <td className="px-6 py-3"></td>
                        <td className="px-6 py-3"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="my-6">
                <button className="bg-[#2B87C8] text-white text-[16px] py-2 px-8 rounded-[3rem]">
                  Email Quote
                </button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[40%]">
            <div className="flex justify-start mb-4 md:mb-0 md:justify-end">
              <div className="flex flex-col gap-4">
                <div className="bg-[#2B87C8] w-full md:w-[320px] text-white shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div>
                    <h4 className="text-[16px]">Landlord Protection</h4>
                    <p className="text-[14px] font-light">Value Plus</p>
                  </div>
                  <hr className="my-4" />
                  <div className="flex items-center gap-4 cursor-pointer">
                    <h4 className="text-[16px]">
                      Product Disclosure Statement
                    </h4>
                    <MdArrowOutward className="w-5 h-5" />
                  </div>
                  <hr className="my-4" />
                  <div>
                    <h4 className="text-[16px]">Estimated Premium</h4>
                    <h5 className="text-[32px] font-semibold pb-2">
                      {"$361.98"}
                    </h5>
                    <p className="text-[12px] font-light">
                      {"(Including GST and Government Charges)"}
                    </p>
                  </div>
                </div>
                <div className="bg-white w-full md:w-[320px] text-[#576263] border border-[#CDD4D4] shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Property Address
                    </h4>
                    <p className="text-[14px] font-light">
                      {address || "Not specified."}
                    </p>
                  </div>
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Period of Insurance
                    </h4>
                    <p className="text-[14px] font-light">
                      {startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : "Not specified."}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-[16px] font-semibold">Quote Ref</h4>
                    <p className="text-[14px] font-light">Q-588820</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-end mt-4">
          <button
            onClick={decrementStep}
            className="flex bg-[#657375] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            <FaArrowLeft className="text-[14px]" />
            Previous
          </button>
          <button
            disabled={isSaving}
            className="flex bg-[#2B87C8] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
            onClick={async () => {
              sendData();
            }}
          >
            {isSaving ? (
              <div className="flex items-center">
                <ClipLoader color={"#FFFFFF"} size={20} />
              </div>
            ) : (
              <span className="flex gap-1 items-center">
                Submit
                <FaArrowRight className="text-[14px]" />
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
