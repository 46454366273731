import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "components/navbar";

import domesticRental from "./assets/domestic-rental.png";
import holidayHomeUnit from "./assets/holiday-home-unit.png";

export default function GetQuotePage() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    localStorage.setItem("get_a_quote", option);
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="bg-[#F6F6F9] w-full min-h-screen overflow-hidden">
        <section className="container mx-auto my-24">
          <div className="max-w-[85vw] mx-auto">
            <div className="relative bg-white p-5 rounded-xl">
              <div className="flex flex-col justify-center">
                <div className="mx-auto text-center my-6">
                  <h1 className="text-[#202020] text-[32px] font-bold">
                    Get a Quote
                  </h1>
                  <div className="mt-6">
                    <h3 className="text-[#202020] text-[20px] font-semibold">
                      Do you have a question?
                    </h3>
                    <p className="text-[#202020] text-md mt-2">
                      Feel free to call us at{" "}
                      <span className="text-[#2B87C8] font-semibold">
                        {"+61-1-2345-6789-0"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row mx-auto gap-14 my-6">
                  {/* Option 1 */}
                  <div
                    onClick={() => handleSelectOption("domestic")}
                    className={`bg-[#F7FAFD] ${
                      selectedOption === "domestic"
                        ? "border-2 border-[#2B87C8]"
                        : "border border-[#CDD4D4]"
                    } max-w-[410px] rounded-xl shadow-lg p-5 hover:border-2 hover:border-[#2B87C8] hover:scale-105 hover:cursor-pointer transition duration-300 ease-in-out`}
                  >
                    <img src={domesticRental} alt="Domestic Rental Option" />
                    <h3 className="text-[#202020] text-[20px] font-semibold text-center mt-2">
                      Domestic Rental
                    </h3>
                  </div>
                  {/* Option 2 */}
                  <div
                    onClick={() => handleSelectOption("holiday")}
                    className={`bg-[#F7FAFD] ${
                      selectedOption === "holiday"
                        ? "border-2 border-[#2B87C8]"
                        : "border border-[#CDD4D4]"
                    } max-w-[410px] rounded-xl shadow-lg p-5 hover:border-2 hover:border-[#2B87C8] hover:scale-105 hover:cursor-pointer transition duration-300 ease-in-out`}
                  >
                    <img src={holidayHomeUnit} alt="Holiday Home Unit Option" />
                    <h3 className="text-[#202020] text-[20px] font-semibold text-center mt-2">
                      Holiday Home or Unit
                    </h3>
                  </div>
                </div>
                <div className="mx-auto my-6">
                  <button
                    onClick={() => navigate("/protection")}
                    className="bg-[#2B87C8] text-white text-[16px] py-3 px-14 rounded-[3rem]"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
