import React, { useState } from "react";

import {
  propertyTypes,
  insurerList,
  externalWalls,
  roofTypes,
} from "../data/lists";

export default function CustomisedCover({ customerForm, setCustomerForm }) {
  function handleReplacementValueChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      replacementValue: e.target.value,
    }));
  }

  function handleTypeOfPropertyChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      typeOfProperty: e.target.value,
    }));
  }

  function handleExternalWallChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      constructionOfExternalWalls: e.target.value,
    }));
  }

  function handleRoofTypeChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      constructionOfRoof: e.target.value,
    }));
  }

  function handleBuildingDateChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      buildingDate: e.target.value,
    }));
  }

  function handleInsureBuildingChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      insure_building: e,
    }));
  }

  function handleRentChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      tenants_default_or_rent: e,
    }));
  }

  function handleContentMoreThanChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      is_contents_more_than: e,
    }));
  }

  function handlePropertyListedChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_listed: e,
    }));
  }

  function handleActiveLeasesChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      activeLeases: e.target.value,
    }));
  }

  function handleRentFrequencyChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      rentFrequency: e.target.value,
    }));
  }

  function handleClaimsChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      claims: e,
    }));
  }

  const handleInsurerChange = (e) => {
    setCustomerForm((prevState) => ({
      ...prevState,
      currentInsurer: e.target.value,
    }));
  };

  return (
    <div>
      <div className="w-full mb-6">
        <div className="mb-5">
          <p className="text-[#576263] text-[16px] font-light mb-2">
            Insure building?
          </p>
          <div className="flex gap-2 mt-2">
            <button
              onClick={() => handleInsureBuildingChange("no")}
              className={`text-white py-1 px-4 rounded-[3rem] ${
                customerForm.insure_building === "no"
                  ? "bg-[#2B87C8]"
                  : "bg-[#657375]"
              }`}
            >
              No
            </button>
            <button
              onClick={() => handleInsureBuildingChange("yes")}
              className={`text-white py-1 px-4 rounded-[3rem] ${
                customerForm.insure_building === "yes"
                  ? "bg-[#2B87C8]"
                  : "bg-[#657375]"
              }`}
            >
              Yes
            </button>
          </div>
        </div>
        <div className="mb-5">
          <p className="text-[#576263] text-[16px] font-light mb-2">
            Tenants default or rent?
          </p>
          <div className="flex gap-2 mt-2">
            <button
              onClick={() => handleRentChange("default")}
              className={`text-white py-1 px-4 rounded-[3rem] ${
                customerForm.tenants_default_or_rent === "default"
                  ? "bg-[#2B87C8]"
                  : "bg-[#657375]"
              }`}
            >
              Default
            </button>
            <button
              onClick={() => handleRentChange("rent")}
              className={`text-white py-1 px-4 rounded-[3rem] ${
                customerForm.tenants_default_or_rent === "rent"
                  ? "bg-[#2B87C8]"
                  : "bg-[#657375]"
              }`}
            >
              Rent
            </button>
          </div>
        </div>
        <div className="mb-5 flex flex-row gap-8">
          <div>
            <p className="text-[#576263] text-[16px] font-light mb-2">
              Is contents more than {"$70,000"}?
            </p>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() => handleContentMoreThanChange("no")}
                className={`text-white py-1 px-4 rounded-[3rem] ${
                  customerForm.is_contents_more_than === "no"
                    ? "bg-[#2B87C8]"
                    : "bg-[#657375]"
                }`}
              >
                No
              </button>
              <button
                onClick={() => handleContentMoreThanChange("yes")}
                className={`text-white py-1 px-4 rounded-[3rem] ${
                  customerForm.is_contents_more_than === "yes"
                    ? "bg-[#2B87C8]"
                    : "bg-[#657375]"
                }`}
              >
                Yes
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="replacementValue"
              className="text-[#576263] text-[16px] font-light mb-2"
            >
              Replacement value of content
            </label>
            <input
              type="text"
              id="replacementValue"
              name="replacementValue"
              placeholder="1"
              value={customerForm.replacementValue}
              onChange={handleReplacementValueChange}
              className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[225px]"
            />
          </div>
        </div>
      </div>
      <div className="w-[80%] mb-6">
        <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
          Property Details
        </h2>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="typeOfProperty"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Type of Property?
          </label>
          <select
            id="typeOfProperty"
            name="typeOfProperty"
            value={customerForm.typeOfProperty}
            onChange={handleTypeOfPropertyChange}
            className="form-select text-[#202020] px-4 py-2 rounded-md border border-gray-300 w-[300px] focus:border-blue-500 focus:ring-blue-500"
          >
            {propertyTypes.map((type) => (
              <option
                key={type}
                value={type}
                className="text-[14px] text-gray-700"
              >
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="constructionOfExternalWalls"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Construction of external walls?
          </label>
          <select
            id="constructionOfExternalWalls"
            name="constructionOfExternalWalls"
            value={customerForm.constructionOfExternalWalls}
            onChange={handleExternalWallChange}
            className={`form-select text-[#202020] px-4 py-2 rounded-md border border-gray-300 w-[300px] focus:border-blue-500 focus:ring-blue-500 ${
              customerForm.constructionOfExternalWalls === ""
                ? "text-[#ABB5B5]"
                : ""
            }`}
          >
            <option value="" className="text-[14px] text-[#ABB5B5]" disabled>
              Select your external wall type
            </option>
            {externalWalls.map((type) => (
              <option
                key={type}
                value={type}
                className="text-[14px] text-gray-700"
              >
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="constructionOfExternalWalls"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Construction of roof?
          </label>
          <select
            id="constructionOfRoof"
            name="constructionOfRoof"
            value={customerForm.constructionOfRoof}
            onChange={handleRoofTypeChange}
            className={`form-select text-[#202020] px-4 py-2 rounded-md border border-gray-300 w-[300px] focus:border-blue-500 focus:ring-blue-500 ${
              customerForm.constructionOfRoof === "" ? "text-[#ABB5B5]" : ""
            }`}
          >
            <option value="" className="text-[14px] text-[#ABB5B5]" disabled>
              Select your roof type
            </option>
            {roofTypes.map((type) => (
              <option
                key={type}
                value={type}
                className="text-[14px] text-gray-700"
              >
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="buildingDate"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Year of construction of building
          </label>
          <input
            type="text"
            id="buildingDate"
            name="buildingDate"
            placeholder="e.g. 2000"
            value={customerForm.buildingDate}
            onChange={handleBuildingDateChange}
            className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
          />
        </div>
        <div className="mb-5">
          <p className="text-[#576263] text-[16px] font-light">
            Is the property heritage listed?
          </p>
          <div className="flex gap-2 mt-2">
            <button
              onClick={() => handlePropertyListedChange("no")}
              className={`text-white py-1 px-4 rounded-[3rem] ${
                customerForm.property_listed === "no"
                  ? "bg-[#2B87C8]"
                  : "bg-[#657375]"
              }`}
            >
              No
            </button>
            <button
              onClick={() => handlePropertyListedChange("yes")}
              className={`text-white py-1 px-4 rounded-[3rem] ${
                customerForm.property_listed === "yes"
                  ? "bg-[#2B87C8]"
                  : "bg-[#657375]"
              }`}
            >
              Yes
            </button>
          </div>
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="activeLeases"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            How many lease Agreement are active or will be in place at this
            property?
          </label>
          <input
            type="text"
            id="activeLeases"
            name="activeLeases"
            placeholder="e.g. 1"
            className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
            onChange={handleActiveLeasesChange}
          />
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="rentFrequency"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Total weekly rent
          </label>
          <input
            type="text"
            id="rentFrequency"
            name="rentFrequency"
            placeholder="e.g. $400"
            className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
            onChange={handleRentFrequencyChange}
          />
        </div>
      </div>
      <div className="w-[80%] mb-6">
        <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
          Claims
        </h2>
        <p className="text-[#576263] text-[16px] font-light mb-2">
          Have you had 3 or more claims over the past 5 years, or any one claim
          exceeding $10,000 on any rental property or dweling?
        </p>
        <div className="flex gap-2 mt-5">
          <button
            onClick={() => handleClaimsChange("no")}
            className={`text-white py-1 px-4 rounded-[3rem] ${
              customerForm.claims === "no" ? "bg-[#2B87C8]" : "bg-[#657375]"
            }`}
          >
            No
          </button>
          <button
            onClick={() => handleClaimsChange("yes")}
            className={`text-white py-1 px-4 rounded-[3rem] ${
              customerForm.claims === "yes" ? "bg-[#2B87C8]" : "bg-[#657375]"
            }`}
          >
            Yes
          </button>
        </div>
      </div>
      <div className="w-[80%] mb-6">
        <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
          Current Insurance
        </h2>
        <div className="flex flex-col mb-4">
          <label
            htmlFor="currentInsurer"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Who is your current insurer?
          </label>
          <select
            id="currentInsurer"
            name="currentInsurer"
            value={customerForm.currentInsurer}
            onChange={handleInsurerChange}
            className={`form-select text-[#202020] px-4 py-2 rounded-md border border-gray-300 w-[300px] focus:border-blue-500 focus:ring-blue-500 ${
              customerForm.currentInsurer === "" ? "text-[#ABB5B5]" : ""
            }`}
          >
            <option value="" className="text-[14px] text-[#ABB5B5]" disabled>
              Select your insurer
            </option>
            {insurerList.map((insurer) => (
              <option
                key={insurer}
                value={insurer}
                className="text-[14px] text-gray-700"
              >
                {insurer}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
