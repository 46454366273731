import React, { useState } from "react";

import { propertyTypes, insurerList } from "../data/lists";

export default function LandlordCover({ customerForm, setCustomerForm }) {
  const handleInsurerChange = (e) => {
    setCustomerForm((prevState) => ({
      ...prevState,
      currentInsurer: e.target.value,
    }));
  };

  function handleTypeOfPropertyChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      typeOfProperty: e.target.value,
    }));
  }

  function handleActiveLeasesChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      activeLeases: e.target.value,
    }));
  }

  function handleRentFrequencyChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      rentFrequency: e.target.value,
    }));
  }

  function handleClaimsChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      claims: e,
    }));
  }

  return (
    <div>
      <div className="w-[80%] mb-6">
        <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
          Property Details
        </h2>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="typeOfProperty"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Type of Property?
          </label>
          <select
            id="typeOfProperty"
            name="typeOfProperty"
            onChange={handleTypeOfPropertyChange}
            className="form-select text-[#202020] px-4 py-2 rounded-md border border-gray-300 w-[300px] focus:border-blue-500 focus:ring-blue-500"
          >
            {propertyTypes.map((type) => (
              <option
                key={type}
                value={type}
                className="text-[14px] text-gray-700"
              >
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="activeLeases"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            How many lease Agreement are active or will be in place at this
            property?
          </label>
          <input
            type="text"
            id="activeLeases"
            name="activeLeases"
            placeholder="e.g. 1"
            className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
            onChange={handleActiveLeasesChange}
          />
        </div>
        <div className="flex flex-col mb-2">
          <label
            htmlFor="rentFrequency"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Total weekly rent
          </label>
          <input
            type="text"
            id="rentFrequency"
            name="rentFrequency"
            placeholder="e.g. $400"
            className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-[300px]"
            onChange={handleRentFrequencyChange}
          />
        </div>
      </div>
      <div className="w-[80%] mb-6">
        <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
          Claims
        </h2>
        <p className="text-[#576263] text-[16px] font-light mb-2">
          Have you had 3 or more claims over the past 5 years, or any one claim
          exceeding $10,000 on any rental property or dweling?
        </p>
        <div className="flex gap-2 mt-5">
          <button
            onClick={() => handleClaimsChange("no")}
            className={`text-white py-1 px-4 rounded-[3rem] ${
              customerForm.claims === "no" ? "bg-[#2B87C8]" : "bg-[#657375]"
            }`}
          >
            No
          </button>
          <button
            onClick={() => handleClaimsChange("yes")}
            className={`text-white py-1 px-4 rounded-[3rem] ${
              customerForm.claims === "yes" ? "bg-[#2B87C8]" : "bg-[#657375]"
            }`}
          >
            Yes
          </button>
        </div>
      </div>
      <div className="w-[80%] mb-6">
        <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
          Current Insurance
        </h2>
        <div className="flex flex-col mb-4">
          <label
            htmlFor="currentInsurer"
            className="text-[#576263] text-[16px] font-light mb-2"
          >
            Who is your current insurer?
          </label>
          <select
            id="currentInsurer"
            name="currentInsurer"
            value={customerForm.currentInsurer}
            onChange={handleInsurerChange}
            className={`form-select text-[#202020] px-4 py-2 rounded-md border border-gray-300 w-[300px] focus:border-blue-500 focus:ring-blue-500 ${
              customerForm.currentInsurer === "" ? "text-[#ABB5B5]" : ""
            }`}
          >
            <option value="" className="text-[14px] text-[#ABB5B5]" disabled>
              Select your insurer
            </option>
            {insurerList.map((insurer) => (
              <option
                key={insurer}
                value={insurer}
                className="text-[14px] text-gray-700"
              >
                {insurer}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
